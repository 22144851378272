import { NextPage } from 'next'
import dynamic from 'next/dynamic'

// Meta Tags
import MetaTags from '@/components/common/meta-tags'

// Page Component
import HomeHeroSection from '@/components/page-components/home-page/hero-section'
import HomeAboutSection from '@/components/page-components/home-page/home-about'
import HomeOurServices from '@/components/page-components/home-page/home-services'
import StatisticsSection from '@/components/page-components/home-page/statistics'

const HomeWorkSection = dynamic(() => import('@/components/page-components/home-page/home-works'), { ssr: false })

const Home: NextPage = () => {
  return (
    <>
      <MetaTags
        title="Comfort - The Home Craft"
        description="Comfort The Home Craft - one stop shop for your furniture, bed, bed mattress, chairs and sofa"
        keywords="beds, mattress, bed, chair, sofa, "
      />

      <HomeHeroSection />

      <HomeAboutSection />

      <HomeOurServices />

      <StatisticsSection />

      <HomeWorkSection />
    </>
  )
}

export default Home
