import { Box, styled } from '@mui/material'

// Global UI
import { TypographyH1 } from '@/ui'

export const HeroSecBg = styled(Box)(({ theme }) => ({
  background: 'url(/pages/home-page/hero-section/hero_section_bg.webp)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  padding: `${theme.typography.pxToRem(20)} 0 0 ${theme.typography.pxToRem(15)}`,

  [theme.breakpoints.between('xs', 'sm')]: {
    backgroundSize: 'auto',
    minHeight: theme.typography.pxToRem(500),
  },
  [theme.breakpoints.up('md')]: {
    backgroundSize: 'cover',
    minHeight: theme.typography.pxToRem(640),
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}))

export const HeroSecH1 = styled(TypographyH1)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(34),
  lineHeight: 1.4,
  color: '#3b3d42',

  '& .headline_first': {
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(42),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(70),
    },
    color: '#FFF',
    fontWeight: 300,
    lineHeight: '1.08em',
    textAlign: 'center',
  },
  '& .headline_second': {
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(42),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(70),
    },
    color: '#FFF',
    fontWeight: 'bold',
    lineHeight: '1.08em',
    textAlign: 'center',
  },
}))
