import { Avatar, styled } from '@mui/material'

// Global UI
import { TypographyH2, TypographyH3, TypographyP } from '@/ui'

export const OurServicesH2 = styled(TypographyH2)(({ theme }) => ({
  fontWeight: 300,
  fontSize: theme.typography.pxToRem(44),
  lineHeight: 1.08,
  color: '#372d2b',
  textTransform: 'capitalize',

  '& .firstHeadline': {
    fontSize: theme.typography.pxToRem(44),
    fontWeight: 300,
    lineHeight: '1.04em',
    textAlign: 'center',
  },
  '& .secondHeadline': {
    fontSize: theme.typography.pxToRem(44),
    fontWeight: 'bold',
    lineHeight: '1.04em',
    textAlign: 'center',
  },
}))

export const CustomAvatar = styled(Avatar)(({ theme }) => ({
  background: 'transparent',
  width: theme.typography.pxToRem(50),
  height: theme.typography.pxToRem(50),

  '& .MuiSvgIcon-root': {
    fontSize: theme.typography.pxToRem(40),
  },
}))

export const ServiceNameH3 = styled(TypographyH3)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(22),
  fontWeight: 400,
  lineHeight: '1.55em',
  margin: 0,
  padding: 0,
  marginBlock: 0,
}))

export const ServiceDescP = styled(TypographyP)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: 300,
  lineHeight: '1.6em',
  margin: 0,
  padding: 0,
}))
