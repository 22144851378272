import React, { FC } from 'react'

// Nextjs
import Head from 'next/head'
import { useRouter } from 'next/router'

// PartyTown
import { Partytown } from '@builder.io/partytown/react'

// Constant
import { CONSTANTS } from '@/constants'

type MetaTagsProps = {
  title?: string
  description?: string
  keywords?: string
}

const MetaTags: FC<MetaTagsProps> = props => {
  const { description, keywords, title } = props

  const router = useRouter()

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="icon" href="/logo/icongen/favicon.ico" />
      <link rel="alternate" href={`${CONSTANTS.SHARE_URL}${router.asPath}`} hrefLang="en-us" />
      <link rel="alternate" href={`${CONSTANTS.SHARE_URL}${router.asPath}`} hrefLang="x-default" />

      <link rel="alternate" hrefLang="en-us" href={`${CONSTANTS.SHARE_URL}${router.asPath}`} />

      {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}

      {/* OG Meta Tags */}
      <meta property="og:url" content={`${CONSTANTS.SHARE_URL}${router.asPath}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${CONSTANTS.SHARE_URL}/logo/comfort_half_transparent_200x200.png`} />
      <meta property="og:image:url" content={`${CONSTANTS.SHARE_URL}/logo/comfort_half_transparent_200x200.png`} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:image:alt" content="Comfort - The Home Craft" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@comfort-the-home-craft" />
      <meta name="twitter:creator" content="@comfort-the-home-craft" />
      <meta name="twitter:domain" content={`${CONSTANTS.SHARE_URL}${router.asPath}`} />
      <meta name="twitter:url" content={`${CONSTANTS.SHARE_URL}${router.asPath}`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${CONSTANTS.SHARE_URL}/logo/comfort_half_transparent_300x157.png`} />
      <link rel="canonical" href={`${CONSTANTS.SHARE_URL}${router.asPath}`} />

      <Partytown />
    </Head>
  )
}

export default MetaTags
