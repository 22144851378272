import React, { FC } from 'react'

// MUI
import { Box, Container, Grid } from '@mui/material'

// Global UI
import { PaddTwentyFour } from '@/ui'
import { CountNumber, CountText, StatisticsParentDiv } from './styles'

const StatisticsSection: FC = () => {
  return (
    <StatisticsParentDiv>
      <Container maxWidth="xl">
        <PaddTwentyFour>
          <Box sx={{ textAlign: 'center' }}>
            <Grid container justifyContent="space-between" alignItems="center" spacing={4}>
              <Grid item xs={6} sm={4} md={3}>
                <CountNumber>5,000</CountNumber>
                <CountText>Finished Projects</CountText>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <CountNumber>2,000</CountNumber>
                <CountText>New Designs</CountText>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <CountNumber>10</CountNumber>
                <CountText>Team Members</CountText>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <CountNumber>3,000</CountNumber>
                <CountText>Happy Clients</CountText>
              </Grid>
            </Grid>
          </Box>
        </PaddTwentyFour>
      </Container>
    </StatisticsParentDiv>
  )
}

export default StatisticsSection
