import { styled } from '@mui/material'

// Global UI
import { TypographyH2, TypographyP, TypographySpan } from '@/ui'

export const FurnitureMakerText = styled(TypographySpan)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  fontWeight: 300,
  lineHeight: '1.7em',
  textAlign: 'center',
}))

export const FurnitureH2 = styled(TypographyH2)(({ theme }) => ({
  fontWeight: 300,
  fontSize: theme.typography.pxToRem(44),
  lineHeight: 1.08,
  color: '#372d2b',

  '& .firstHeadline': {
    fontSize: theme.typography.pxToRem(44),
    fontWeight: 300,
    lineHeight: '1.04em',
    textAlign: 'center',
  },
  '& .secondHeadline': {
    fontSize: theme.typography.pxToRem(44),
    fontWeight: 'bold',
    lineHeight: '1.04em',
    textAlign: 'center',
  },
}))

export const ParaText = styled(TypographyP)(({ theme }) => ({
  textAlign: 'center',
  color: '#000',
  fontSize: theme.typography.pxToRem(17.5),
  fontWeight: 300,
  lineHeight: '1.6em',
}))
