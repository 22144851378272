import React, { FC } from 'react'

// MUI
import { Box, Container, Typography } from '@mui/material'

// UI
import { DividerLine, PaddTwentyFour } from '@/ui'
import { FurnitureH2, FurnitureMakerText, ParaText } from './styles'

const HomeAboutSection: FC = () => {
  return (
    <Box sx={{ py: theme => theme.typography.pxToRem(50), textAlign: 'center' }}>
      <Container maxWidth="md">
        <PaddTwentyFour component="section">
          <FurnitureMakerText>Furniture Maker</FurnitureMakerText>

          <FurnitureH2>
            <Typography variant="body2" variantMapping={{ body2: 'span' }} className="firstHeadline">
              A Furniture in
            </Typography>{' '}
            <Typography variant="body2" variantMapping={{ body2: 'span' }} className="secondHeadline">
              League of it&apos;s Own!
            </Typography>
          </FurnitureH2>

          <DividerLine />

          <br />

          <ParaText>
            Since 1991 our small but dedicated team of professional woodworkers, carpenters, designers and furniture manufacturers was joining forces for crafting functional and visually flawless
            furniture pieces. Be it a artificial grass, curtains, mattress, sofa &amp; sofa set, wallpapers and window blinds - altogether we&apos;ve got more than 1000 orders completed by now. The
            length of our combined experience and all of the skills of our craftsmen put together result in our pieces featuring high durability and tasteful visual appeal. Be sure to drop us a letter
            and order yourself a piece too!
          </ParaText>
        </PaddTwentyFour>
      </Container>
    </Box>
  )
}

export default HomeAboutSection
