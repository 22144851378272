import { Box, styled } from '@mui/material'

// Global UI
import { TypographyP, TypographySpan } from '@/ui'

export const StatisticsParentDiv = styled(Box)(({ theme }) => ({
  background: '#bdb29c',
  padding: `${theme.typography.pxToRem(60)} 0`,
  transition: 'all 3s ease',
}))

export const CountNumber = styled(TypographySpan)(({ theme }) => ({
  color: '#FFF',
  fontSize: theme.typography.pxToRem(36),
  fontWeight: 900,
  lineHeight: '0.75em',
}))

export const CountText = styled(TypographyP)(({ theme }) => ({
  color: '#FFF',
  fontSize: theme.typography.pxToRem(17.5),
  fontWeight: 300,
  lineHeight: 2.5,
  margin: 0,
}))
