import React, { FC } from 'react'

// MUI
import { Box, Container, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'

// Data
import { HomeOurServiceData } from '@/data'

// UI
import { DividerLine, PaddTwentyFour } from '@/ui'
import { CustomAvatar, OurServicesH2, ServiceDescP, ServiceNameH3 } from './styles'

const HomeOurServices: FC = () => {
  return (
    <Box sx={{ py: theme => theme.typography.pxToRem(50) }}>
      <Container maxWidth="xl">
        <PaddTwentyFour component="section">
          <Box textAlign="center">
            <OurServicesH2>our services</OurServicesH2>

            <DividerLine />
          </Box>

          <br />

          <Box sx={{ my: 5 }}>
            <Grid container justifyContent="space-between" alignItems="center" spacing={4}>
              {HomeOurServiceData.map((data, idx) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={idx}>
                    <Box sx={{ display: 'grid', placeItems: 'center' }}>
                      <List disablePadding>
                        <ListItem disableGutters>
                          <ListItemAvatar sx={{ mr: 2 }}>
                            <CustomAvatar variant="square">{data.icon}</CustomAvatar>
                          </ListItemAvatar>
                          <ListItemText disableTypography primary={<ServiceNameH3>{data.title}</ServiceNameH3>} secondary={<ServiceDescP>{data.desc}</ServiceDescP>} />
                        </ListItem>
                      </List>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </PaddTwentyFour>
      </Container>
    </Box>
  )
}

export default HomeOurServices
