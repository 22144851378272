import React, { FC } from 'react'

// MUI
import { Container, Typography } from '@mui/material'

// UI
import { PaddTwentyFour } from '@/ui'
import { HeroSecBg, HeroSecH1 } from './styles'

const HomeHeroSection: FC = () => {
  return (
    <HeroSecBg component="div">
      <Container maxWidth="xl">
        <PaddTwentyFour component="div">
          <HeroSecH1>
            <Typography variant="body2" variantMapping={{ body2: 'span' }} className="headline_first">
              We turn your Furniture
            </Typography>
            <br />
            <Typography variant="body2" variantMapping={{ body2: 'span' }} className="headline_second">
              Fantesiest to Reality!
            </Typography>
          </HeroSecH1>
        </PaddTwentyFour>
      </Container>
    </HeroSecBg>
  )
}

export default HomeHeroSection
